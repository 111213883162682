<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />

        <div style="margin-bottom:70px;">
            <bread-crumbs :items="items"></bread-crumbs> 
            <tabs-comp :tabs="tabs" />
                <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:15px !important;`">
                    <v-col
                        cols="12" md="3" sm="12" class="text-start mt-8">
                        <b-input-group>
                            <b-form-select class="selborder" :options="StatusLists" v-model="currentStatus" @change="getExpensesTypes()" />
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                <i class="fas fa-arrow-down"></i>
                                </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    <v-col
                        cols="12" md="9" sm="12" class="text-end mt-8">
                        <b-button variant="success"  
                        class="btn-sm addemployeecl" 
                        :style="`width:190px;margin-`+lang.lalgin+`:6px;background:transparent;border:none`" flat 
                        v-b-toggle.expense_type 
                        id="multiply-button"
                        @click="resetMe({name_ar: '',name_en:'',id:0,bgColor: '#ffffff',color: '#000000'})"
                        color="success">
                        <div style="width:190px;color:#000" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_new_status}}</div>
                        </b-button>
                    </v-col>
                <v-col cols="12" style="margin-top:-7px;">
                    <v-simple-table dense fixed-header>
                        <thead>
                            <th class="text-center backBlack" style="width:50px"></th>
                            <th class="text-center backBlack">اسم الحالة بالعربية</th>
                            <th class="text-center backBlack">اسم الحالة بالانجليزي</th>
                            <th class="text-center backBlack">{{ lang.action }}</th>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in expenses" :key="index">
                                <td class="text-center" :style="`background:${item.bgColor} !important;color:${item.color} !important`">{{item.value}}</td>
                                <td class="text-center">
                                    {{ expenses[index].name_ar }}
                                </td>
                                <td class="text-center">
                                    {{ expenses[index].name_en }}
                                </td>
                                <td class="text-center" style="width:70px;background:blue;border-bottom:2px solid #fff !important;">
                                    <v-btn style="box-shadow:none;width:70px;font-size:0.2em;background:blue;color:#FFF;height:25px !important;margin-inline-end:5px;" @click="resetMe(item)" v-b-toggle.expense_type>{{lang.update}}</v-btn>
                                </td>
                            </tr>
                            <!-- <tr>
                                <th colspan="4" style="background:#c3d9ff !important"  >{{lang.add_new_expense_type}}</th>
                            </tr>
                            <tr>
                                <td></td>
                                <td><b-form-input class="inborder" v-model="newline.name_ar"></b-form-input></td>
                                <td><b-form-input class="inborder" v-model="newline.name_en"></b-form-input></td>
                                <td class="text-center" style="width:60px;background:green;border-bottom:1px solid #fff !important;">
                                    <v-btn style="width:100px;font-size:0.2em;background:green;color:#FFF;height:25px !important;margin-inline-end:5px;" @click="CreateNew()">{{lang.add}}</v-btn>
                                </td>
                            </tr> -->
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>
        </div>
        <Footer />
        <addExpensesType ref="expensType" />
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import addExpensesType from '@/components/addStatusType.vue'
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
import ExportsPrint from '@/components/exports-print.vue'
export default {
    components: {BreadCrumbs, TabsComp, HeaderPortrate, Footer, addExpensesType,ExportsPrint},
    data() {
        return {
            currentStatus: 'Calendar',
            expenses: [],
            newline: {
                name_ar: '',
                name_en: '',
                id:0,
                bgColor: '#ffffff',
                color: '#000000',
            }
        }   
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        StatusLists: function(){
            return [
                {text: 'حالة الحجوزات',value: 'Calendar'},
                {text: 'انواع الحجوزات',value: 'calendarTypes'},
                {text: 'حالة العملاء',value: 'customerStatus'},
                // {text: 'حالة الاصلاحات على الضمان',value: 'warantyWork'},
                {text: 'حالة المهام',value: 'taskStatus'},
                {text: 'اجراءات العملاء',value: 'crmActions'},
                {text: 'حالات العملاء المحتملين',value: 'crmStatus'},
                {text: 'نوع العمل',value: 'crmType'},
                {text: 'الصناعيات',value: 'inDustrial'},
                {text: 'حالات المهام الداخلية',value: 'taskHomeStatus'},
                {text: 'حالات الدعم الفني',value: 'supportHomeStatus'},
                {text: 'المدن',value: 'citiesNames'},
                {text: 'الأهمية',value: 'Priorities'},
                {text: 'انواع المشاريع',value: 'ProjectTypes'},
                {text: 'حالة المشاريع',value: 'ProjectStatus'},
            ]
        },
        items: function(){
            return '';
        },
        tabs: function()
        {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        }
    },
    methods: {
        preparedata(){
            let exdata = {
                list: "expensesList",
                title: this.lang.expenses,
                data: [],
            }
            for(let i=0;i<this.expenses.length;i++){
                exdata.data.push(this.expenses[i]);
            }
            // console.log(exdata);
            this.$refs.exportbar.exportdata = exdata;
            this.$refs.exportbar.sdate = '';
            this.$refs.exportbar.edate = '';
            
            this.$refs.exportbar.exporttitle = "";
            this.$refs.exportbar.palte_number = '';
            this.$refs.exportbar.contact_number = '';
        },
        resetMe(item){
            this.$refs.expensType.resetValues(item);
        },
        EditThis(index){
            const post = new FormData();
            post.append("type" , "updateStaticOption");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[phrase]',this.currentStatus);
            post.append('data[name_ar]',this.expenses[index].name_ar);
            post.append('data[name_en]',this.expenses[index].name_en);
            post.append('data[id]',this.expenses[index].id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then(() => {
                let message = this.lang.expenses_type_updated;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.getExpensesTypes();
            })
        },
        CreateNew(){
            const post = new FormData();
            post.append("type" , "addStaticOption");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[phrase]',this.currentStatus);
            post.append('data[name_ar]',this.newline.name_ar);
            post.append('data[name_en]',this.newline.name_en);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then(() => {
                this.newline.name_ar = '';
                this.newline.name_en = '';
                let message = this.lang.expenses_type_added;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.getExpensesTypes();
            })
        },
        getExpensesTypes(){
            const post = new FormData();
            post.append("type" , "getStaticOptions");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[phrase]',this.currentStatus);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                this.expenses = response.data.results.data;
            })
        }
    },
    created() {
        this.getExpensesTypes();
    }
}
</script>